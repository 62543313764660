@font-face {
  font-family: THICCCBOI;
  /* src: url("./fonts/Webfont/THICCBOI-Bold.eot"); */
  src: url("../../assets/fonts/Webfont/THICCCBOI-Bold.woff2") format("woff2"),
    /* url("/fonts//THICCBOI-Bold.woff") format("woff"), */
      url("../../assets/fonts/TTF/THICCCBOI-Bold.ttf") format("truetype");
  /* url('/fonts/customFont.svg#customFont') format('svg'), */
  /* url('/fonts/customFont.eot?#iefix') format('embedded-opentype'); */
  font-weight: normal;
  font-style: normal;
}

button {
  text-decoration: none;
  width: 100%;
  font-size: 18px;
  border: solid 1.2px rgb(70, 70, 70);
  background: #141414;
  box-shadow: 1px 20px 45px rgba(0, 0, 0, 0.3);
  border-radius: 50px;
  /* font-color: white; */
  color: white;
  padding: 8px 15px;
  font-family: THICCCBOI;
}

body {
  background-color: #eef1f4;
}
