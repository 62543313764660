* {
    margin: 0%;
    /* top: 0; */
    /* bottom: 0; */
    padding: 0;
  }
  
  body {
    margin: 0%;
    top: 0;
    bottom: 0;
    padding: 0;
  }
  .wrapper {
    text-align: center;

  }
  .App {
    height: 100vh;

  }
  