@font-face {
    font-family: THICCCBOI;
    /* src: url("./fonts/Webfont/THICCBOI-Bold.eot"); */
    src: url("../../assets/fonts/Webfont/THICCCBOI-Bold.woff2") format("woff2"),
      /* url("/fonts//THICCBOI-Bold.woff") format("woff"), */
        url("../../assets/fonts/TTF/THICCCBOI-Bold.ttf") format("truetype");
    /* url('/fonts/customFont.svg#customFont') format('svg'), */
    /* url('/fonts/customFont.eot?#iefix') format('embedded-opentype'); */
    /* font-weight: normal; */
    /* font-style: normal; */
  }
  
  .card {
    margin: 2rem 2rem 2rem 1rem;
    width: 250px;
    box-shadow: 0 0 28px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    cursor: pointer;
    border: 0.8px solid rgb(70,70,70);
    /* border-radius: 10%; */
    position: relative;
    background-color:  #141414;
    text-align: center;
  }
  
  .card:hover {
    transform: scale(1.05);
  }
  
  .card__title {
    /* position: absolute; */
    font-weight: 800;
    font-size: 1.5rem;
    background-color: #141414;
    /* border-top-left-radius: 25px;
    border-top-right-radius: 25px; */
    /* padding: 10px; */
    padding-top: 5px;
    margin-top: 0px;
    width: auto;
    color: white;
    text-align: center;
  }
  
  .card__image {
    display: flex;
    justify-content: flex-end;
  }
  /* .card__image img {
    border-radius: 10%;
  } */
  img {
    width: 100%;
    height: 100%;
  }
  
  .card__body {
    padding: 10px;
    display: flex;
    justify-content: space-around;
  }
  .card__body button {
    color: white;
    box-shadow: none;
    background-color: rgba(0, 0, 0, 0.856);
    /* border: 1.5px solid black; */
    font-size: 15px;
    padding: 5px 12px;
    font-family: "sans-serif";
}

.tag {
  background: rgb(255, 255, 255);
  /* width: 60px;
  height: 30px; */
  /* line-height: 30px; */
  text-align: center;
  font-size: 20px;
  color: #d68800;
  font-weight: 1000;
  position: absolute;
  top: 0;
  padding: 0px 1px;
}