.foo-svg {
  /* background-color: aqua; */
  width: 100px;
  height: 100px;
  fill: currentColor;
  color: #3182ce41;
  display: block;
  fill: none;
  stroke: currentcolor;
}
.css-1rrwmzt {
  position: absolute;
  stroke: currentcolor;
  fill: none;
  display: block;
  color: #2d3748;
  width: 4rem;
  left: 30%;
  /* top: 65%; */
  margin-top: 5rem;
}
.css-1wvgl9v {
  position: absolute;
  stroke: none;
  fill: currentcolor;
  display: block;
  color: #4a5568;
  width: 4rem;
  left: 70%;
  /* top: 90%; */
}

/* cross with pink stroke */
.css-ihhqwk {
  position: absolute;
  stroke: currentcolor;
  fill: none;
  display: block;
  color: #ed64a6;
  width: 4rem;
  left: 28%;
  top: 0;
  margin-top: -100px;
}

/* square */
.css-uw67g2 {
  position: absolute;
  stroke: none;
  fill: currentcolor;
  display: block;
  color: #2d3748;
  width: 3rem;
  left: 40%;
  top: 30%;
}

.css-1fyybsj {
  width: 4rem;
  fill: currentColor;
  color: #3182ce;
  top: 0;
  left: 0;
}

.css-1dadoe5 {
  position: absolute;
  stroke: none;
  fill: currentcolor;
  display: block;
  color: #2d3748;
  width: 2rem;
  left: 95%;
  top: 90%;
}

.css-x9ahbb {
  position: absolute;
  stroke: none;
  fill: currentcolor;
  display: block;
  color: #48bb78;
  width: 16rem;
  left: 95%;
  top: 5%;
}
