.about {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    background-color: transparent;
    left: 0;
    word-wrap: break-word;
    overflow-x: auto;
    width: calc();
    font-size: 1em;
    font-weight: normal;
  }
.about p > span {
  color: rgb(64, 131, 207);
  padding: 0;
  font-weight: bold;
}