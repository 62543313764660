/* @import-normalize; */
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600&display=swap);

:root {
  --dark: #101315;
  --light: #eeeeee;
  --gradient: linear-gradient(10deg, #ffaa00, #ff6a00);
  --gradient2: linear-gradient(15deg, #04ea00, #00d17d);
  --gradient3: linear-gradient(15deg, #b648ff, #ef5dff);
  font-size: 1rem;
}

.backdrop {
    z-index:1;
    position: absolute;
    height: 100vh;
    width: 100vw;
    background: #000000e1;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-y: hidden;

    display: block; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 55; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  }
  
  .modal {
    width: clamp(50%, 600px, 90%);
    height: auto;
  color: rgb(236, 232, 232);
    margin: auto;
    padding: 2rem;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color:  #141414;
    z-index: 2; 
  }
  
  .orange-gradient {
    background: var(--gradient);
  }
  
  .green-gradient {
    background: var(--gradient2);
  }
  
  .pink {
    color: #c273ff;
  }
  
  .gray {
    color: #666666;
  }
  
  .light-blue {
    color: #00b7ff;
  }
  
  .modal-button {
    position: relative;
    bottom: 1.5rem;
    padding: 0 3rem;
    min-height: 3rem;
    margin: auto auto 0 auto;
    background: var(--dark);
    color: var(--light);
  }
  
  .save-button {
    padding: 0.5rem 1rem;
    /* margin: 2rem auto auto 0; */
    background: var(--dark);
    color: var(--light);
  }
  
  .close-button {
    padding: 0 2rem;
    height: 2.5rem;
    margin: 2rem auto 1rem 0;
    background: #101111;
    border: 1px dashed #9a9a9a99;
    color: #ffaa00;
    border-radius: 4px;
    transition: background ease 400ms;
    box-shadow: 1px 1px 15px #03030399;
  }
