@import url('https://fonts.googleapis.com/css2?family=Crimson+Pro&display=swap');

.project-card {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }
.project-head {
  font-family: 'Crimson Pro',ui-serif,Cambria,"Times New Roman",Times,serif;
    font-weight: 700;
    line-height: 1.25;
    margin: 0.5rem 0px;
    display: block;
    font-size: 3rem;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    -webkit-font-smoothing: antialiased;
   color: #fff;
}