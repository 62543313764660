@import url("https://fonts.googleapis.com/css2?family=Crimson+Pro&display=swap");

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.svg {
  /* width: 20%; */
  /* height: 50%; */
  position: relative;
  display: flex;
  justify-content: space-between;
  /* z-index: -1; */
  /* width: 100vw;
  height: 100vh; */
  /* background-color: aqua; */
  position: absolute;
  inset: 0px;
}
#wrapper {
  font-family: "Crimson Pro", serif;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  height: 100vh;
}

.content {
  /* color: #ffffff; */
  font-size: 26px;
  font-weight: bold;
  /* text-shadow: -1px -1px 1px #000, 1px 1px 1px #000; */
  position: relative;
  z-index: 100;
  width: 100%;
  height: 100%;
}

.back {
  color: #999999;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -100;
  /* background-color: red; */
  width: 100%;
  height: 100%;
  background: #141821;
  /* position: absolute; */
  inset: 0px;
  background-size: auto;
  background-repeat: no-repeat;
  will-change: transform;
  width: 100%;
  height: 100rem;
  transform: translate3d(0px, -20px, 0px);
}
